import React from "react";

export default function footer () {
return (
    <footer class="sticky-footer">
        <div class="footer-content">
            <h3>Narrow Studios</h3>
            <ul class="footer-links">
                <li><a href="/home" class="footer-link">Home</a></li>
                <li><a href="/projects" class="footer-link">Projects</a></li>
                <li><a href="/gallery" class="footer-link">Code</a></li>
                <li><a href="/about" class="footer-link">About</a></li>
            </ul>
            <div class="footer-bottom">
                <p>Copyright ©2024 All rights reserved.</p>
            </div>
        </div>
    
    </footer>
)
}