import React from "react"
import ReactDOM from "react-dom"

import JsLogo from "../images/js-logo.png"


export default function GalleryItem(props) {
    return (
        <div id="galleryItem" class="gallery-item">
            <h2>{props.title}</h2>
            <div class="gallery-container">
                <p>{props.body}</p>
                
            </div>
            <a href={props.link}>Explore</a>
        </div>
    )
}