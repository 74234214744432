import React from "react";
import GalleryItem from "./gallery_item";

const content = [
    {
        title: "Python Shop Tracker",
        body: "Python web scraping tool to monitor in stock alerts at online retailers",
        image: "../images/python-logo.png",
        link: ""
    },
    {
        title: "Narrow Studios",
        body: "Portfolio website built with HTML, CSS, JS, and React",
        image: "../images/react-logo.png",
        link: "https://narrowstudios.com"
    },
    {
        title: "Python Gym Notifier",
        body: "Python project using requests and pandas to find open swim lanes and decode their times",
        image: "../images/python-logo.png",
        link: ""
    },
    {
        title: "Island Hacks Hackathon 2023",
        body: "Chat messaging website used to simulate talking to a chatbot. Built with bootstrap and JS as a 12 hour group challenge",
        image: "require(../images/js-logo.png)",
        link: ""
    }
    
]



export default function galleryData() {
    const elements = content.map((contentElements) => {
        return(<GalleryItem title={contentElements.title} body={contentElements.body} image={contentElements.image} link={contentElements.link}/>)
    })
    return(elements)
}