import React from "react";
import ReactDOM from "react-dom"
import GalleryData from "./components/galleryData";
import About from "./components/pages/about"
import Gallery from "./components/pages/gallery"
import Home from "./components/pages/home"
import Projects from "./components/pages/projects"
import Navbar from "./components/navbar"
import { inject } from '@vercel/analytics';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FooterComponent from "./components/footer.js"
import SingleBlogPost from "./components/pages/single-blog-post.js"


inject();

function Index() {
    return (
        
        <BrowserRouter>
        <Navbar></Navbar>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/:slug" element={<SingleBlogPost />} />
            </Routes>
            <FooterComponent/>
        </BrowserRouter>
        
    )
}


ReactDOM.render(
    <Index />, document.getElementById("root")
)