import React from "react";
import ProjectsItem from "../projects-item"
import Footer from "../footer"

export default function projects() {
    return (
        <div>
        <div className="projects-container">
            <h2 className="page-heading">Projects</h2>
            <ProjectsItem></ProjectsItem>
        </div>
        </div>
    )
}