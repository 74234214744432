import React from "react";
import GitLogo from "../../images/git.png"
import ReactLogo from "../../images/react-logo.png"
import YoutubeLogo from "../../images/youtube.png"
import CubeLogo from "../../images/cube.png"

export default function about() {
    return (
        <div className="about">
            <h2 className="page-heading">About</h2>
            <div className="about-grid">
                <div className="about-logo-container">
                    <img className="about-logo" height="300px" width="300px" src={ReactLogo}></img>
                </div>
                <div className="about-container">
                    <p className="about-body">Narrow Studios is a website built entirely using React to display a wide variety of personal projects and applications.</p>
                    <div>
                        <h1 className="about-title">The Developer</h1>
                        <div className="about-socials-container">
                            <div class="social-item">
                                <a target="_blank" alt="" href="https://github.com/NarrowJS">
                                    <img className="social-item-logo" height="50px" width="50px" src={GitLogo}></img>
                                </a>
                            </div>
                            <div class="social-item">
                                <a target="_blank" alt="" href="https://youtube.com/@narrow-studios">
                                    <img className="social-item-logo" height="50px" width="50px" src={YoutubeLogo}></img>
                                </a>
                            </div>
                            <div class="social-item">
                                <a target="_blank" alt="" href="https://www.printables.com/@Narrow_1874442">
                                    <img className="social-item-logo" height="50px" width="50px" src={CubeLogo}></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}