import React from "react";
import rightImage from "../../images/website-home-vector-2.png"
import Footer from "../footer"


export default function about() {
    return (
        <div>
        <div class="content-container">
            <div class="content">
                <div class="left-content-container">
                    <div class="slogan-content"><p>Design. Build. Code.</p></div>
                    <div class="slogan-body">
                        <p>Find inspiration for projects and access materials and 3D printed cases for your next build.</p>
                    </div>
                    
            
                    <div class="left-btn-container">
                        <a href="/gallery" class="explore-btn">Explore</a>
                    </div>
                    
                </div>
                
                <div class="right-image-container">
                    <img class="rightImage" alt="" src={rightImage}></img>
                </div>
            </div>
        </div>

        </div>
    )
}