import { useState, useEffect } from "react"
import client from "../../client"
import { Link, useParams } from "react-router-dom"
import BlockContent from "@sanity/block-content-to-react"
import { format } from "date-fns"





export default function SingleBlogPost() {
    const [singlePost, setSinglePost] = useState([])
    const { slug } = useParams()

    useEffect(() => {
        client.fetch(`*[slug.current == "${slug}"] {
      title,
      slug,
      image {
        asset -> {
          _id,
          url
        },
        alt,
      },
      body,
      publishedAt,
    }`).then((data) => setSinglePost(data[0])).catch(console.error)
    }, [slug])


    const publishDate = singlePost.publishedAt;

    return <>
        <div className="blog-template-wrapper">
            <div className="blog-template-header">
                <section><p>{new Date(publishDate).toDateString()}</p></section>
                <h1>{singlePost.title}</h1>
                {singlePost.image && singlePost.image.asset && (<img src={singlePost.image.asset.url} alt={singlePost.title}></img>)}
                
            </div>
            <BlockContent className="blog-block-content"
                blocks={singlePost.body}
                projectId={client.projectId}
                dataset={client.dataset}
            />

            <div className="explore-more-container">
            <Link className="explore-more-btn" to="/projects">Explore More Projects</Link>
            </div>
        </div>
    </>
}