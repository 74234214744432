import React from "react";
import GalleryData from "../galleryData";
import Footer from "../footer"

export default function gallery() {
    return (
        <div className="portfolio-group">
        <h2 className="page-heading">Code</h2>
        <div className="gallery-grid">
            <GalleryData />
        </div>
        </div>
    )
}