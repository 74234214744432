import React, { useState, useEffect } from "react";
import client from "../../src/client";
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(client);

function urlFor(source) {
    console.log(builder.image(source));
  return builder.image(source);
}


function BlogPosts() {
    const [posts, setPosts] = useState([]);
  
    useEffect(() => {
      async function fetchPosts() {
        try {
          const data = await client.fetch(`*[_type == "post"]`);
          setPosts(data);
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      }
  
      fetchPosts();
    }, []);
  
    // Render your blog posts using the 'posts' state
    return (
      <div className="projects-grid">
        {posts.map((post) => (
            <div className="projects-item">
                <div className="projects-item-image-container">
                <img className="projects-item-image" src={urlFor(post.image).url()}></img>
                </div>
                <p className="projects-item-title"> <a href={`/projects/${post.slug.current}`}><span className="projects-item-cursor"></span>{post.title}</a></p>
                <p className="projects-item-description">{post.description}</p>
            </div>
        ))}
      </div>
    );
  }
  
  export default BlogPosts;






/*

export default function projectsitem() {
    return (
        <div className="projects-item">
            <div className="projects-item-image-container">
                <img className="projects-item-image" src={"https://media.printables.com/media/prints/798963/images/6198940_006607d3-d131-430d-9144-0c7a8ebe68c0_50e951c5-df53-40a2-83a3-8f550acc92d2/thumbs/inside/1280x960/png/wled-case-title.webp"}></img>
            </div>
            <p className="projects-item-title"> <a href={"https://www.printables.com/model/798963-wled-case-for-esp8266-node-mcu"}><span className="projects-item-cursor"></span>WLED Case</a></p>
        </div>
    )
}

*/
