import React from "react";

export default function navbar() {
    return (
        <div class="navbar">
            <img className="logo-icon" src={require("../images/N_Logo_2.png")} />
            <div class="navbar-items-container">
                <div class="links">
                    <a href="/">Home</a>
                    <a href="/projects">Projects</a>
                    <a href="/gallery">Code</a>
                    <a href="/about">About</a>
                </div>
            </div>
        </div>
    )
}